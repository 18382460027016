body {
    background-color: rgb(13,61,90);
}

.background {
    background-color: rgb(13,61,90);
    position: relative;

    width:100vw;
    height:160vh;
}
.Resume {
    position: absolute; /* or absolute */
    left: 60%;
    transform: translate(-60%, 0%);
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-top:7vh;
    width:60%;
    text-align: left;
    padding:80px;
    background-color: whitesmoke;
}

@media screen and (max-width: 1600px) {
    .Resume {
        position: absolute; /* or absolute */
        left: 60%;
        transform: translate(-60%, 0%);
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        margin-top:2vh;
        width:80vw;
        text-align: left;
        padding:30px;
        background-color: whitesmoke;
    }
}

@media only screen and (max-device-width: 640px) {
    .Resume {
        position: absolute; /* or absolute */
        left: 5%;
        transform: translate(-5%, 0%);
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        margin-top:2vh;
        width:100vw;
        text-align: left;
        padding:30px;
        background-color: whitesmoke;
    }
}

.Resume-header {
    font-size: calc(15px + 4vmin);
    color:rgb(13,61,90)
}

.Resume h3 {
    color:rgb(13,61,90)
}

/* .Resume-header {
    min-height: 100vh;
    margin:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(216, 216, 216);
    background-position: center center;
    background-repeat: no-repeat;
} */