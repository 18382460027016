.App {
  text-align: center;
  overflow-y: hidden;
  background-color: rgb(13,61,90);
  border: 5px solid transparent;
}

.App-header {
  background-color: rgba(0,0,0,0);
  min-height: 100vh;
  margin:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(232, 232, 232);
  background-position: center center;
  background-repeat: no-repeat;
}

.WallPaper {
  position: absolute;
  z-index:3;
  top:0; 
  left: 0; 
  background-color: rgba(0, 0, 0, 0);
  background-size: cover;
  height: 100vh;
  width:100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin:0;
}

.socialIcons {
  align-items: center;
  justify-content: center;
}

.Welcome {
  font-size: calc(25px + 7vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-icon {
  margin:15px;
}